<template>
  <GalleryGrid />

  <Image v-if="media.type === 'image'" :media='media' />
  <Video v-else-if="media.type === 'video'" />

</template>

<script>
import mediaData from "@/photos.json";
import GalleryGrid from "@/components/GalleryGrid";
import Image from "@/components/Image"
import Video from "@/components/Video"
import { computed } from '@vue/runtime-core';
import { useRoute } from "vue-router";
import _ from "lodash";

export default {
  name: "Media",

  props: {},

  components: {
    GalleryGrid,
    Image,
    Video
  },

  setup() {
    const route = useRoute();

    const media = computed(() => {
      return _.find(mediaData, { 'id': Number(route.params.id) });
    });

    return { media };
  },
};
</script>

<style lang="scss" scoped>
</style>
