<template>
  <div
    id="overlay"
    class="overlay"
    tabindex="0"
    @keyup.left="prevMedia((id = media.id))"
    @keyup.right="nextMedia((id = media.id))"
    @keyup.esc="close"
    @click="overlayClick"
  >
    <div class="overlay--content">
      <div class="details">


        <div class="image">
          <div class="button--prev" @click="prevMedia((id = media.id))">
            <span class="sr-only">previous</span>
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30,12L18,24l12,12"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <figure>
            <img v-lazy="imageSrc" :alt="media.alt" />

            <figcaption>
              <div v-if="media.caption">{{ media.caption }}</div>
              <div class="buttons">
                <!-- <div class="download"> -->
                <a :href="media.filename" title="download image" download>

                  <span class="sr-only">download</span>
                  <img src="../assets/icons/download.svg" alt="">
                </a>
                <!-- </div> -->
                <div class="close" @click="close">
                  <span class="sr-only">close</span>
                  <img src="../assets/icons/cancel.svg" alt="">
                </div>
              </div>
            </figcaption>
          </figure>

          <div class="button--next" @click="nextMedia((id = media.id))">
            <span class="sr-only">next</span>
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18,36l12-12L18,12"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { computed, onBeforeMount, onMounted } from "@vue/runtime-core";

export default {
  name: "Image",

  props: {
    media: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();

    const close = () => {
      document.getElementById("app").className = "";
      router.push({ name: "Gallery" });
    };

    const imageSrc = computed(() => {
      return (
        props.media.filename.slice(0, 58) +
        "x50" +
        props.media.filename.slice(57)
      );
    });

    function nextMedia(id) {
      let nextID = id + 1;

      if (nextID > 0) {
        router.push({
          name: "Media",
          params: { id: nextID },
        });
      }
    }

    function prevMedia(id) {
      let prevID = id - 1;

      if (prevID > 0) {
        router.push({
          name: "Media",
          params: { id: prevID },
        });
      }
    }

    const overlayClick = () => {
      // console.log("clicked overlay")
    };

    onBeforeMount(() => {});

    onMounted(() => {
      document.getElementById("overlay").focus();
      document.getElementById("app").className = "openmodal";
    });

    return { overlayClick, imageSrc, prevMedia, nextMedia, close };
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  box-sizing: border-box;
  contain: strict;
  font-size: 18px;
  left: 0;
  line-height: 1.5;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;

  &--content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  .details {
    background: white;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 5px;
    padding-top: 5px;

    &--prev-next {
      display: flex;

      * {
        margin-left: 10px;
      }
    }

    .close {
      margin-right: 10px;
    }
  }

  .image {
    display: flex;
    min-height: 420px;
    min-width: 420px;
    position: relative;

    .button--prev,
    .button--next {
      background-color: rgba(255, 255, 255, 0.75);
      cursor: pointer;
      height: 48px;
      position: absolute;
      top: 50%;
    }
    .button--next {
      right: 0;
    }
  }

  figure {
    margin: 0;
    padding: 0;

    figcaption {
      margin: 0 auto;
      padding: 5px 10px;
    }
  }

  img {
    display: block;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    max-height: 85vh;
    max-width: 85vw;
    width: auto;
  }
}
</style>
