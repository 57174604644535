<template>
  VIDEO RENDER!
</template>

<script>
export default {
  name: "Video",

  setup() {
  },
}
</script>

<style lang="scss" scoped>
</style>
